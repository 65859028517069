import VehiclesDetail from '../Vehicles/Details/store/VehiclesDetailsModule';
import Availability from '../Availability/store/AvailabilityModule';
import BookingManagement from '../Bookings/Management/store/BookingDetailsModule';
import BookingSets from '../BookingSets/store/BookingSetsModule';
import Cities from '../Cities/store/CitiesModule';
import Planning from '../Planning/store/PlanningModule';
import AdditionalCosts from '../Bookings/Details/AdditionalCosts/store/AdditionalCostsModule';
import Prebookings from '../Prebookings/store/PrebookingsModule';
import BookingDetails from '../Bookings/Details/store/BookingDetailsModule';
import Vehicles from '../Vehicles/store/VehiclesModule';
import VehicleDamages from '../Vehicles/VehicleDamages/store/VehicleDamagesModule';
import Investigation from '../Vehicles/VehicleDamages/Investigation/store/InvestigationModule';

export default {
  namespaced: true,
  modules: {
    AdditionalCosts,
    Availability,
    BookingDetails,
    BookingManagement,
    BookingSets,
    Cities,
    Investigation,
    Planning,
    Prebookings,
    Vehicles,
    VehiclesDetail,
    VehicleDamages,
  },
};
